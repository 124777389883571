export const STATUS = [
  {
    id: 0,
    name: "Creada",
  },
  {
    id: 1,
    name: "Creada",
  },
  {
    id: 2,
    name: "En Almacen",
  },
  {
    id: 3,
    name: "Enrutado foraneo",
  },
  {
    id: 4,
    name: "En ruta",
  },
  {
    id: 5,
    name: "Con vendedor",
  },
  {
    id: 6,
    name: "Archivada",
  },
  {
    id: 7,
    name: "Cancelada",
  },
  {
    id: 8,
    name: "Perdida",
  },
  {
    id: 9,
    name: "Entregada",
  },
  {
    id: 10,
    name: "Ruta programada",
  },
  {
    id: 11,
    name: "No entregada",
  },
  {
    id: 12,
    name: "Entrega parcial",
  },
  {
    id: 13,
    name: "Entregado cliente",
  },
  {
    id: 14,
    name: "Entregado vendedor",
  },
  {
    id: 15,
    name: "Cliente recoge",
  },
  {
    id: 16,
    name: "Vendedor recoge",
  },
  {
    id: 17,
    name: "Refacturacion",
  },
  {
    id: 18,
    name: "En SAC",
  },
  {
    id: 19,
    name: "En CXC",
  },
];

export const departamentos = [
  {
    id: 0,
    name: "Sin departamento",
  },
  {
    id: 1,
    name: "SAC",
  },
  {
    id: 2,
    name: "CXC",
  },
  {
    id: 3,
    name: "Almacen",
  },
  {
    id: 4,
    name: "Ventas",
  },
  {
    id: 5,
    name: "Reparto",
  },
  {
    id: 6,
    name: "Seguridad",
  },
  {
    id: 7,
    name: "Gerencia",
  },
];

export const STATUS_SELECT = [
  {
    value: 1,
    label: "Creada",
  },
  {
    value: 2,
    label: "En Almacen",
  },
  // {
  //     value: 3,
  //     label: "Enrutado foraneo"
  // },
  {
    value: 4,
    label: "En ruta",
  },
  {
    value: 5,
    label: "Con vendedor",
  },
  {
    value: 6,
    label: "Archivada",
  },
  {
    value: 7,
    label: "Cancelada",
  },
  // {
  //     value: 8,
  //     label: "Perdida"
  // },
  {
    value: 9,
    label: "Entregada",
  },
  {
    value: 10,
    label: "Ruta programada",
  },
  {
    value: 11,
    label: "No entregada",
  },
  {
    value: 12,
    label: "Entrega parcial",
  },
  {
    value: 13,
    label: "Entregado cliente",
  },
  {
    value: 14,
    label: "Entregado vendedor",
  },
  {
    value: 15,
    label: "Cliente recoge",
  },
  {
    value: 16,
    label: "Vendedor recoge",
  },
  {
    value: 17,
    label: "Refacturacion",
  },
  {
    value: 18,
    label: "En SAC",
  },
  {
    value: 19,
    label: "En CXC",
  },
];

export const TIPO_DCTO = [
  {
    value: "I",
    label: "RV",
  },
  {
    value: "E",
    label: "NC",
  },
  {
    value: "R",
    label: "R",
  },
];

/**
 * !Deprecated
 * 
 */
// export const SUBSIDIARIES = [
//   {
//     value: 1,
//     label: "TRA1",
//   },
//   {
//     value: 2,
//     label: "TRA2",
//   },
//   {
//     value: 3,
//     label: "TRA3",
//   },
//   {
//     value: 5,
//     label: "TRA5",
//   },
//   {
//     value: 6,
//     label: "TRA6",
//   },
//   {
//     value: 7,
//     label: "TRA7",
//   },
//   {
//     value: 8,
//     label: "TRA8",
//   },
//   {
//     value: 9,
//     label: "TRA9",
//   },
// ];

export const DEPARTAMENTOS = [
  {
    value: 0,
    label: "Todos",
  },
  {
    value: 1,
    label: "SAC",
  },
  {
    value: 2,
    label: "CXC",
  },
  {
    value: 3,
    label: "ALM",
  },
  {
    value: 4,
    label: "VND",
  },
  {
    value: 5,
    label: "REP",
  },
  {
    value: 7,
    label: "GER",
  },
];

export const TIPO_MOVIMIENTO = [
  // {
  //   value: "todos",
  //   label: "Todos",
  // },
  {
    value: "receive",
    label: "Recibidas",
  },
  {
    value: "created",
    label: "Enviadas",
  },
];

export const ROLES = {
  SubsidiaryAdmin: 5,
  User: 4,
  Gestor: 3,
  Admin: 2,
  SuperUser: 1,
};


export const RECEPTION_OPTIONS = [
  {
    label: "Sin recibir",
    value: "Sin recibir",
  },
  {
    label: "Original firma y/o sello cliente",
    value: "Original firma y/o sello cliente",
  },
  {
    label: "Copia firma y/o sello cliente",
    value: "Copia firma y/o sello cliente",
  },
  {
    label: "Copia firma vendedor",
    value: "Copia firma vendedor",
  },
  {
    label: "Ventas ExxonMobil",
    value: "Ventas ExxonMobil",
  },
  {
    label: "Paquetería",
    value: "Paqueteria",
  },
  {
    label: "Reenvio",
    value: "Reenvio",
  },
  {
    label: "Pago con transferencia",
    value: "Pago con transferencia",
  },
  {
    label: "Pagaré",
    value: "Pagaré",
  },
  {
    label: "Autorizado por CXC",
    value: "Autorizado por CXC",
  },
  {
    label: "Contrarecibo",
    value: "Contrarecibo",
  },
  {
    label: "Pago en efectivo",
    value: "Pago en efectivo",
  },
  {
    label: "Llantas",
    value: "Llantas",
  },
  {
    label: "Devolución",
    value: "Devolucion",
  },
];

export const INVOICE_CATEGORIES = [
  {
    label: "Sin categoria",
    value: "Sin categoria",
  },
  {
    label: "Local",
    value: "Local",
  },
  {
    label: "Local Programado",
    value: "Local Programado",
  },
  {
    label: "Foráneo",
    value: "Foráneo",
  },
  {
    label: "Foráneo Programado",
    value: "Foráneo Programado",
  },
  {
    label: "Granel",
    value: "Granel",
  },
  {
    label: "Llantas",
    value: "Llantas",
  },
  {
    label: "Cliente recoge",
    value: "Cliente recoge",
  },
  {
    label: "Vendedor recoge",
    value: "Vendedor recoge",
  },
  {
    label: "Pendiente pago",
    value: "Pendiente pago",
  },
]

export const LUBTRAC_EXTRA_CATEGORIES = [
  {
    label: "Vendedor confirma",
    value: "Vendedor confirma",
  },
  {
    label: "IND Foranea (Leon)",
    value: "IND Foranea (Leon)",
  },
  {
    label: "Industria Molykote",
    value: "Industria Molykote",
  },
  {
    label: "Industria Monterrey",
    value: "Industria Monterrey",
  }
]

export const INCIDENT_CATEGORIES = [
  {
    value: "1",
    label: "Producto incorrecto",
  },
  {
    value: "2",
    label: "Cantidad incorrecta"
  },
  {
    value: "3",
    label: "Apariencia insatisfactoria"
  },
  {
    value: "4",
    label: "Contenedor dañado"
  },
  {
    value: "5",
    label: "Etiqueta faltante"
  },
  {
    value: "6",
    label: "Paquete con insuficiente llenado"
  },
  {
    value: "7",
    label: "Producto dañado"
  }
]