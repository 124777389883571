import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import url from "../url";
import { STATUS } from "../constants";

export const scannQr = createAsyncThunk(
  "/api/factura/detalleqr",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat("api/factura/detalleqr"), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        params: params,
      })
      .then((response) => {
        if (params?.route) {
          response.data.route = 1;
        }
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const scannQrCategory = createAsyncThunk(
  "/api/factura/detalleqr/category",
  async ({ qr_url }, thunkAPI) => {
    return await axios
      .get(url.concat("api/factura/detalleqr"), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        params: {
          qr_address: qr_url,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response);
      });
  }
);

export const authSender = createAsyncThunk(
  "/api/usuarios/validarSender",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/usuarios/validar"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return [response.data, data];
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const authReceiver = createAsyncThunk(
  "/api/usuarios/validarReceiver",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/usuarios/validar"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return [response.data, data];
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const entregaFacturas = createAsyncThunk(
  "/api/transacciones/entrega",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/transacciones/entrega"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return [response.data, data];
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const recogeFacturas = createAsyncThunk(
  "/api/transacciones/entrega/cliente_recoge",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/transacciones/recoge"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch(async (e) => {
        let responseObj = await e.response.data.text();
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(responseObj);
      });
  }
);

export const postCobranzaTransaction = createAsyncThunk(
  "/api/transacciones/entrega/cxc",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/transacciones/cobranza"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const postArchivadoTransaction = createAsyncThunk(
  "/api/transacciones/archivado/cxc",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/transacciones/archivado"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const salidaRuta = createAsyncThunk(
  "/api/transacciones/ruta/salida",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/transacciones/ruta/salida"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const entradaRuta = createAsyncThunk(
  "/api/transacciones/ruta/entrada",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/transacciones/ruta/entrada"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getCamiones = createAsyncThunk(
  "/api/camiones",
  async (thunkAPI) => {
    return await axios
      .get(url.concat("api/camiones/miscamiones"), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const entregaProducto = createAsyncThunk(
  "/api/transacciones/entrega/producto",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/transacciones/entrega/producto"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const noEntregaFactura = createAsyncThunk(
  "/api/transacciones/no_entrega/producto",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/transacciones/noentrega/producto"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getHistorialTransacciones = createAsyncThunk(
  "/api/transacciones/historial",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat("api/transacciones/historial"), {
        params: params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getDetalleTransacciones = createAsyncThunk(
  "/api/transacciones/historial/detalle",
  async ({ transaction_id }, thunkAPI) => {
    return await axios
      .get(url.concat("api/transacciones/historial/detalle"), {
        params: {
          transaction_id: transaction_id,
        },
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const postBadQrFactura = createAsyncThunk(
  "/api/factura/badqr",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/factura/badqr"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/zip" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", data.folio_factura);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(fileURL);
        return response.headers["extra-data"];
      })
      .catch(async (e) => {
        let responseObj = await e.response.data.text();
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(responseObj);
      });
  }
);

const initialState = {
  category: "Local",
  scannedInvoices: [],
  scannedCategoryInvoices: [],
  scannedRouteInvoices: [],
  observations: [],

  isFetchingScanner: false,
  isSuccessScanner: false,
  isErrorScanner: false,

  isFetchingSender: false,
  isSuccessSender: false,
  isErrorSender: false,

  isFetchingReceiver: false,
  isSuccessReceiver: false,
  isErrorReceiver: false,

  isFetchingEntrega: false,
  isSuccessEntrega: false,
  isErrorEntrega: false,

  isFetchingCobranza: false,
  isSuccessCobranza: false,
  isErrorCobranza: false,

  isFetchingArchivado: false,
  isSuccessArchivado: false,
  isErrorArchivado: false,

  isFetchingSalida: false,
  isSuccessSalida: false,
  isErrorSalida: false,

  isFetchingCamiones: false,
  isSuccessCamiones: false,
  isErrorCamiones: false,

  isFetchingEntregaProducto: false,
  isSuccessEntregaProducto: false,
  isErrorEntregaProducto: false,

  isFetchingEntrada: false,
  isSuccessEntrada: false,
  isErrorEntrada: false,

  isFetchingNoEntrega: false,
  isSuccessNoEntrega: false,
  isErrorNoEntrega: false,

  isFetchingHistorialT: false,
  isSuccessHistorialT: false,
  isErrorHistorialT: false,
  Historial: [],

  isFetchingDetalle: false,
  isSuccessDetalle: false,
  isErrorDetalle: false,
  Detalle: [],

  trucks: [],
  ppRoute: [],
  sender_user_id: 0,
  sender_password: "",
  receiver_user_id: 0,
  receiver_password: "",
  truck_id: 0,
  ruta_id: 0,
  schelude_route_id: 0,
  facturas_ruta: [],
  entregas: [],

  badFolio: "",

  isFetchingBadQr: false,
  isSuccessBadQr: false,
  isErrorBadQr: false,

  successMessage: "",
  errorMessage: "",
  error: [
    {
      code: "",
      message: "",
    },
  ],

  errorData: [], //se utiliza para el picking
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isFetchingScanner = false;
      state.isSuccessScanner = false;
      state.isErrorScanner = false;
      state.errorMessage = "";
      return state;
    },
    clearStateBadQr: (state) => {
      state.isFetchingBadQr = false;
      state.isSuccessBadQr = false;
      state.isErrorBadQr = false;
      state.errorMessage = "";
      return state;
    },
    removeItem: (state, { payload }) => {
      const newArray = state.scannedInvoices.filter(
        (scan) => scan.id !== payload
      );
      state.scannedInvoices = newArray;
      state.isSuccessScanner = true;
    },
    removeItemRoute: (state, { payload }) => {
      const newArray = state.scannedRouteInvoices.filter(
        (scan) => scan.id !== payload
      );
      state.scannedRouteInvoices = newArray;
    },
    removeItemCategory: (state, { payload }) => {
      const newArray = state.scannedCategoryInvoices.filter(
        (scan) => scan.ID !== payload
      );
      state.scannedCategoryInvoices = newArray;
      state.isSuccessScanner = true;
    },
    clearSender: (state) => {
      state.isFetchingSender = false;
      state.isSuccessSender = false;
      state.isErrorSender = false;
      state.errorMessage = "";
      return state;
    },
    clearReceiver: (state) => {
      state.isFetchingReceiver = false;
      state.isSuccessReceiver = false;
      state.isErrorReceiver = false;
      state.errorMessage = "";
      return state;
    },
    clearEntrega: (state) => {
      state.isFetchingEntrega = false;
      state.isSuccessEntrega = false;
      state.isErrorEntrega = false;
      state.transaction_id = 0;
      state.successMessage = "";
      state.errorMessage = "";
      state.errorData = [];
      return state;
    },
    clearNoEntrega: (state) => {
      state.isFetchingNoEntrega = false;
      state.isSuccessNoEntrega = false;
      state.isErrorNoEntrega = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    clearEntregaProducto: (state) => {
      state.isFetchingEntregaProducto = false;
      state.isSuccessEntregaProducto = false;
      state.isErrorEntregaProducto = false;
      state.errorMessage = "";
      return state;
    },
    clearSalida: (state) => {
      state.isFetchingSalida = false;
      state.isSuccessSalida = false;
      state.isErrorSalida = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    clearCamiones: (state) => {
      state.isFetchingCamiones = false;
      state.isSuccessCamiones = false;
      state.isErrorCamiones = false;
      state.errorMessage = "";
      return state;
    },
    clearEntrada: (state) => {
      state.isFetchingEntrada = false;
      state.isSuccessEntrada = false;
      state.isErrorEntrada = false;
      state.errorMessage = "";
      return state;
    },

    reset: () => initialState,
    /**
     * ? Setea el estado de la transacción a su estado inicial
     * @param {Object} state
     *
     */
    resetUsers: (state) => {
      state.sender_user_id = "";
      state.sender_password = "";
      state.receiver_user_id = "";
      state.receiver_password = "";
      state.isSuccessSender = false;
      state.isSuccessReceiver = false;
      state.isErrorSender = false;
      state.isErrorReceiver = false;
      state.errorMessage = "";
      return state;
    },
    resetEntrada: (state) => {
      state.ruta_id = 0;
      state.truck_id = 0;
      state.entregas = [];
      return state;
    },
    addObservation: (state, { payload }) => {
      const newArray = state.observations.concat(payload);
      state.observations = newArray;
      return state;
    },
    selectRoute: (state, { payload }) => {
      state.ruta_id = payload;
      return state;
    },
    selectTruck: (state, { payload }) => {
      state.truck_id = payload;
      return state;
    },
    selectFacturasEntrega: (state, { payload }) => {
      state.entregas = payload;
      return state;
    },
    addEntrega: (state, { payload }) => {
      state.entregas = payload;
      return state;
    },
    clearEntregas: (state) => {
      state.entregas = [];
      return state;
    },
    loadScheludeRoute: (state, { payload }) => {
      const newArray = payload.facturas;
      const updated_array = [];

      newArray.forEach((factura) => {
        updated_array.unshift(factura);
      });

      state.scannedRouteInvoices = updated_array;
      state.schelude_route_id = payload.ruta_id;
      //state.isSuccessScanner = true;
      return state;
    },
    changeCategory: (state, { payload }) => {
      state.category = payload;
      return state;
    },
    clearHistorial: (state) => {
      state.isFetchingHistorialT = false;
      state.isSuccessHistorialT = false;
      state.isErrorHistorialT = false;
      state.errorMessage = "";
      return state;
    },
    clearDetalle: (state) => {
      state.isFetchingDetalle = false;
      state.isSuccessDetalle = false;
      state.isErrorDetalle = false;
      state.errorMessage = "";
      return state;
    },
    clearTransactionHistoryDetail: (state) => {
      state.Historial = [];
      state.Detalle = [];
      return state;
    },
    clearScannedInvoices: (state) => {
      state.scannedInvoices = [];
      return state;
    },
    clearScannedRouteInvoices: (state) => {
      state.scannedRouteInvoices = [];
      return state;
    },
    clearPpRoute: (state) => {
      state.ppRoute = [];
      return state;
    },
    setBadFolio: (state, { payload }) => {
      state.badFolio = payload;
      return state;
    },
    clearStateCobranza: (state) => {
      state.isFetchingCobranza = false;
      state.isSuccessCobranza = false;
      state.isErrorCobranza = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.error = [
        {
          code: "",
          message: "",
        },
      ];

      return state;
    },
    clearStateArchivado: (state) => {
      state.isFetchingArchivado = false;
      state.isSuccessArchivado = false;
      state.isErrorArchivado = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.error = [
        {
          code: "",
          message: "",
        },
      ];

      return state;
    },
  },
  extraReducers: {
    [scannQr.fulfilled]: (state, { payload }) => {
      state.isFetchingScanner = false;
      state.isSuccessScanner = true;

      /**
       * ?Factura escaneada para ruta
       */
      if (payload?.route) {
        const newArray = state.scannedRouteInvoices;
        let index = newArray.findIndex((scan) => scan.id === payload.data.id);
        if (index < 0) {
          newArray.unshift(payload.data);
        }
        if (index >= 0) {
          state.isErrorScanner = true;
          state.errorMessage = "Esta factura ya ha sido escaneada";
        }
      } else {
        /**
         * ?Factura escaneada para transacción express
         */
        const newArray = state.scannedInvoices;
        if (payload.data) {
          let index = newArray.findIndex((scan) => scan.id === payload.data.id);
          if (index < 0) {
            let new_invoice = {
              id: payload.data.id,
              folio: payload.data.folio,
              pedido: payload.data?.complementos[0]
                ? payload.data.complementos[0].pedido_interno
                : "N/A",
              tipodecomprobante:
                payload.data.tipodecomprobante === "I"
                  ? "RV"
                  : payload.data.tipodecomprobante === "E"
                  ? "NC"
                  : "R",
              fecha: payload.data.fecha,
              clave_receptor: payload.data.receptor.clave_receptor,
              nombre_receptor: payload.data?.receptor?.nombre
                ? payload.data.receptor.nombre
                : "N/A",
              categoria: payload.data.categoria
                ? payload.data.categoria
                : "Sin categoría de SAC",
              categoria_alm: payload.data.categoria_alm
                ? payload.data.categoria_alm
                : "Sin categoría de ALM",
              status_id: payload.data?.status?.id,
              status: payload.data?.status?.name,
              total: payload.data.total,
              moneda: payload.data.moneda,
              observaciones: payload.data?.complementos[0]?.observaciones
                ? payload.data.complementos[0].observaciones
                : "N/A",
            };
            newArray.unshift(new_invoice);
          }
          if (index >= 0) {
            state.isErrorScanner = true;
            state.errorMessage = "Esta factura ya ha sido escaneada";
          }
        }
      }
    },
    [scannQr.pending]: (state) => {
      state.isFetchingScanner = true;
    },
    [scannQr.rejected]: (state, { payload }) => {
      let error_message = {};

      if (payload?.data) {
        error_message = {
          message: payload.message + ". " + payload.data.message,
          error:
            payload.data.error && payload.data.error.length > 0
              ? payload.data.error
              : null,
        };
      } else if (payload?.status) {
        error_message = {
          message: payload.message,
        };
      } else {
        error_message = {
          message:
            "Error de conexión con el servidor, verifique su conexión a internet",
        };
      }

      state.isFetchingScanner = false;
      state.isErrorScanner = true;
      state.errorMessage = error_message.error
        ? error_message.message + ". " + error_message.error
        : error_message.message;
    },
    [scannQrCategory.fulfilled]: (state, { payload }) => {
      state.isFetchingScanner = false;
      state.isSuccessScanner = true;
      const newArray = state.scannedCategoryInvoices;

      const new_invoice = {
        ID: payload.data.id,
        Folio: payload.data.folio,
        Pedido: payload.data?.complementos[0]
          ? payload.data.complementos[0].pedido_interno
          : "N/A",
        Fecha: payload.data.fecha,
        "Tipo comprobante":
          payload.data.tipodecomprobante === "I"
            ? "RV"
            : payload.data.tipodecomprobante === "E"
            ? "NC"
            : "R",
        "#Cliente": payload.data.receptor.clave_receptor,
        Cliente: payload.data.receptor.nombre,
        Categoria: state.category,
        // state.category === "10"
        //   ? "Local"
        //   : state.category === "20"
        //     ? "Local Programado"
        //     : state.category === "30"
        //       ? "Foraneo"
        //       : state.category === "40"
        //         ? "Foraneo Programado"
        //         : state.category === "50"
        //           ? "Granel"
        //           : state.category === "60"
        //             ? "Llantas"
        //             : state.category === "70"
        //               ? "Cliente recoge"
        //               : state.category === "80"
        //                 ? "Vendedor recoge"
        //                 : state.category === "90"
        //                   ? "Pendiente pago"
        //                   : "N/A",
        Status: payload.data.status_id
          ? STATUS[payload.data.status_id]["name"]
          : STATUS[1]["name"],
        Total: payload.data.total,
        Moneda: payload.data.moneda,
      };

      const index = newArray.findIndex((scan) => scan.ID === new_invoice.ID);
      if (index < 0) {
        newArray.unshift(new_invoice);
        state.scannedCategoryInvoices = newArray;
      }
      if (index >= 0) {
        state.isErrorScanner = true;
        state.errorMessage = "Esta factura ya ha sido escaneada";
      }
    },
    [scannQrCategory.pending]: (state) => {
      state.isFetchingScanner = true;
    },
    [scannQrCategory.rejected]: (state, { payload }) => {
      let error_message = {};

      if (payload.status === 404) {
        if (payload?.data?.data?.bad_folio) {
          state.badFolio = payload?.data?.data?.bad_folio;
        }
      }

      if (payload.data.data && payload?.status !== 404) {
        error_message = {
          message: payload.data.message + ". " + payload.data.data.message,
          error:
            payload.data.data.error && payload.data.data.error.length > 0
              ? payload.data.data.error
              : null,
        };
      } else if (payload?.status) {
        error_message = {
          message: payload.data.message,
        };
      } else {
        error_message = {
          message:
            "Error de conexión con el servidor, verifique su conexión a internet",
        };
      }

      state.isFetchingScanner = false;
      state.isErrorScanner = true;
      state.errorMessage = error_message.error
        ? error_message.message + ". " + error_message.error
        : error_message.message;
    },
    [authSender.fulfilled]: (state, { payload }) => {
      state.isFetchingSender = false;
      state.isSuccessSender = true;
      state.sender_user_id = payload[0].data.user.id;
      state.sender_password = payload[1].password;
      return state;
    },
    [authSender.pending]: (state) => {
      state.isFetchingSender = true;
    },
    [authSender.rejected]: (state, { payload }) => {
      state.isFetchingSender = false;
      state.isErrorSender = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [authReceiver.fulfilled]: (state, { payload }) => {
      state.isFetchingReceiver = false;
      state.isSuccessReceiver = true;
      state.receiver_user_id = payload[0].data.user.id;
      state.receiver_password = payload[1].password;
      return state;
    },
    [authReceiver.pending]: (state) => {
      state.isFetchingReceiver = true;
    },
    [authReceiver.rejected]: (state, { payload }) => {
      state.isFetchingReceiver = false;
      state.isErrorReceiver = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [entregaFacturas.fulfilled]: (state, { payload }) => {
      state.isFetchingEntrega = false;
      state.isSuccessEntrega = true;
      return state;
    },
    [entregaFacturas.pending]: (state) => {
      state.isFetchingEntrega = true;
    },
    [entregaFacturas.rejected]: (state, { payload }) => {
      state.isFetchingEntrega = false;
      state.isErrorEntrega = true;
      // state.errorMessage = payload?.message
      //   ? payload?.data
      //     ? payload.message + ". " + JSON.stringify(payload.data)
      //     : payload.message
      //   : "Error en el servidor";
      if (payload?.message) {
        state.errorMessage = payload.message;
        if (payload?.error?.message) {
          state.errorMessage += ". " + payload.error.message;
        }

        if (payload?.data?.facturas && payload?.data?.facturas?.length > 0) {
          let foliosFacturas = payload.data.facturas.map(
            (factura) => factura + " "
          );
          state.errorMessage += ". Folios: " + foliosFacturas;
        }

      } else {
        state.errorMessage =
          "Error de conexión, verifique su conexión a internet.";
      }
    },
    [recogeFacturas.fulfilled]: (state) => {
      state.isFetchingEntrega = false;
      state.isSuccessEntrega = true;
      state.successMessage = "Facturas surtidas correctamente";
      return state;
    },
    [recogeFacturas.pending]: (state) => {
      state.isFetchingEntrega = true;
    },
    [recogeFacturas.rejected]: (state, { payload }) => {
      let error_message = JSON.parse(payload);
      state.isFetchingEntrega = false;
      state.isErrorEntrega = true;

      /**
       * Guarda los productos que no se encuentran en la base de productos en el estado errorData
       *
       */

      state.errorMessage = error_message?.message
        ? error_message.message
        : "Error en el servidor al generar el Picking, favor de contactar al administrador";

      if (error_message?.data && error_message?.data.length > 0) {
        state.errorData = error_message.data;
      }
    },
    [postCobranzaTransaction.fulfilled]: (state, { payload }) => {
      state.isFetchingCobranza = false;
      state.isSuccessCobranza = true;
      state.successMessage = payload.message;
      return state;
    },
    [postCobranzaTransaction.pending]: (state) => {
      state.isFetchingCobranza = true;
      return state;
    },
    [postCobranzaTransaction.rejected]: (state, { payload }) => {
      state.isFetchingCobranza = false;
      state.isErrorCobranza = true;
      state.errorMessage = payload.message;
      state.error = payload?.error ?? [
        {
          code: "CONNECION_ERROR",
          message:
            "No se pudo realizar la cobranza, no se obtuvo respuesta del servidor",
        },
      ];
      return state;
    },
    [postArchivadoTransaction.fulfilled]: (state, { payload }) => {
      state.isFetchingArchivado = false;
      state.isSuccessArchivado = true;
      state.successMessage = payload.message;
      return state;
    },
    [postArchivadoTransaction.pending]: (state) => {
      state.isFetchingArchivado = true;
    },
    [postArchivadoTransaction.rejected]: (state, { payload }) => {
      state.isFetchingArchivado = false;
      state.isErrorArchivado = true;
      state.errorMessage = payload.message;
      state.error = payload?.error ?? [
        {
          code: "CONNECION_ERROR",
          message:
            "No se pudo realizar el archivado de facturas, no se obtuvo respuesta del servidor",
        },
      ];
      return state;
    },
    [noEntregaFactura.fulfilled]: (state, { payload }) => {
      state.isFetchingNoEntrega = false;
      state.isSuccessNoEntrega = true;
      return state;
    },
    [noEntregaFactura.pending]: (state) => {
      state.isFetchingNoEntrega = true;
    },
    [noEntregaFactura.rejected]: (state, { payload }) => {
      state.isFetchingNoEntrega = false;
      state.isErrorNoEntrega = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [entregaProducto.fulfilled]: (state, { payload }) => {
      state.isFetchingEntregaProducto = false;
      state.isSuccessEntregaProducto = true;
      return state;
    },
    [entregaProducto.pending]: (state) => {
      state.isFetchingEntregaProducto = true;
    },
    [entregaProducto.rejected]: (state, { payload }) => {
      state.isFetchingEntregaProducto = false;
      state.isErrorEntregaProducto = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [salidaRuta.fulfilled]: (state, { payload }) => {
      state.isFetchingSalida = false;
      state.isSuccessSalida = true;
      state.successMessage = payload.message;
      state.ppRoute = payload.data;
      return state;
    },
    [salidaRuta.pending]: (state) => {
      state.isFetchingSalida = true;
    },
    [salidaRuta.rejected]: (state, { payload }) => {
      state.isFetchingSalida = false;
      state.isErrorSalida = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [entradaRuta.fulfilled]: (state, { payload }) => {
      state.isFetchingEntrada = false;
      state.isSuccessEntrada = true;
      state.successMessage = payload.message;
      state.ppRoute = payload.data;
      return state;
    },
    [entradaRuta.pending]: (state) => {
      state.isFetchingEntrada = true;
    },
    [entradaRuta.rejected]: (state, { payload }) => {
      state.isFetchingEntrada = false;
      state.isErrorEntrada = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [getCamiones.fulfilled]: (state, { payload }) => {
      state.isFetchingCamiones = false;
      state.isSuccessCamiones = true;
      state.trucks = payload.data;
      return state;
    },
    [getCamiones.pending]: (state) => {
      state.isFetchingCamiones = true;
    },
    [getCamiones.rejected]: (state, { payload }) => {
      state.isFetchingCamiones = false;
      state.isErrorCamiones = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [getHistorialTransacciones.fulfilled]: (state, { payload }) => {
      state.isFetchingHistorialT = false;
      state.isSuccessHistorialT = true;
      state.Historial = payload.data;
      return state;
    },
    [getHistorialTransacciones.pending]: (state) => {
      state.isFetchingHistorialT = true;
    },
    [getHistorialTransacciones.rejected]: (state, { payload }) => {
      state.Historial = [];
      state.Detalle = [];
      state.isFetchingHistorialT = false;
      state.isErrorHistorialT = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [getDetalleTransacciones.fulfilled]: (state, { payload }) => {
      state.isFetchingDetalle = false;
      state.isSuccessDetalle = true;
      state.Detalle = payload.data;
      return state;
    },
    [getDetalleTransacciones.pending]: (state) => {
      state.isFetchingDetalle = true;
    },
    [getDetalleTransacciones.rejected]: (state, { payload }) => {
      state.isFetchingDetalle = false;
      state.isErrorDetalle = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [postBadQrFactura.fulfilled]: (state, { payload }) => {
      state.isFetchingBadQr = false;
      state.isSuccessBadQr = true;
      // const facturaFisica = JSON.parse(payload);
      // const newArray = state.scannedCategoryInvoices;

      // const index = newArray.findIndex((scan) => scan.Folio === state.badFolio);

      // const new_invoice = {
      //   id: facturaFisica.id,
      //   Folio: facturaFisica.folio,
      //   Fecha: facturaFisica.fecha,
      //   "#Cliente": facturaFisica.receptor.clave_receptor,
      //   Cliente: facturaFisica.receptor.nombre,
      //   Categoria:
      //     state.category === "10"
      //       ? "Local"
      //       : state.category === "20"
      //       ? "Local Programado"
      //       : state.category === "30"
      //       ? "Foraneo"
      //       : state.category === "40"
      //       ? "Foraneo Programado"
      //       : state.category === "50"
      //       ? "Granel"
      //       : state.category === "60"
      //       ? "Llantas"
      //       : state.category === "70"
      //       ? "Cliente recoge"
      //       : state.category === "80"
      //       ? "Vendedor recoge"
      //       : state.category === "90"
      //       ? "Pendiente pago"
      //       : "N/A",
      //   Status: facturaFisica.status_id
      //     ? STATUS[facturaFisica.status_id]["name"]
      //     : STATUS[1]["name"],
      //   Total: facturaFisica.total,
      // };

      // if (index >= 0) {
      //   newArray[index] = new_invoice;
      // }
      // if (index < 0) {
      //   newArray.unshift(new_invoice);
      // }
      // state.scannedCategoryInvoices = newArray;
    },
    [postBadQrFactura.pending]: (state) => {
      state.isFetchingBadQr = true;
    },
    [postBadQrFactura.rejected]: (state, { payload }) => {
      let error_message = JSON.parse(payload);
      state.isFetchingBadQr = false;
      state.isErrorBadQr = true;
      state.errorMessage = error_message?.data
        ? error_message?.message + ": " + JSON.stringify(error_message.data)
        : error_message?.message
        ? error_message.message
        : "Error en el servidor responsiva";
    },
  },
});

export const {
  clearState,
  removeItem,
  removeItemRoute,
  removeItemCategory,
  reset,
  clearSender,
  clearReceiver,
  clearEntrega,
  clearNoEntrega,
  clearEntregas,
  clearSalida,
  clearEntrada,
  clearPpRoute,
  addObservation,
  clearCamiones,
  clearStateBadQr,
  selectTruck,
  selectRoute,
  selectFacturasEntrega,
  resetUsers,
  addEntrega,
  resetEntrada,
  loadScheludeRoute,
  changeCategory,
  clearHistorial,
  clearDetalle,
  clearEntregaProducto,
  clearTransactionHistoryDetail,
  clearScannedInvoices,
  clearScannedRouteInvoices,
  clearStateCobranza,
  clearStateArchivado,
  setBadFolio,
} = transactionSlice.actions;

export const selectTransaction = (state) => state.transaction;

export default transactionSlice.reducer;
