function calcularVolumenReal(producto) {
  let volumen_real = 0.0;
  let volumen_unitario = 0.0;

  if (producto.entregado) return 0;

  if (!producto.volumen_entregado)
    return parseFloat(producto.volumen).toFixed(3);

  if (!producto?.cantidad || !producto?.volumen) return 0;

  let volumen_entregado = parseFloat(producto.volumen_entregado); //5
  let cantidad = parseFloat(producto.cantidad); //9
  let volumen = parseFloat(producto.volumen); //171

  volumen_unitario = volumen / cantidad; // 171 / 9 = 19
  volumen_real = volumen_unitario * (cantidad - volumen_entregado); // 19 * 5 = 95

  return volumen_real.toFixed(3);
}

function calcularCostoReal(producto) {
  let costo_real = 0.0;

  if (producto.entregado) return 0;

  if (!producto.costo_sap_unitario && !producto.costo_sap) {
    return costo_real.toFixed(2);
  }

  if (!producto?.cantidad)  return costo_real.toFixed(2);

  if(!producto?.volumen_entregado) return parseFloat(producto.costo_sap).toFixed(2);

  let costo_unitario = parseFloat(producto.costo_sap_unitario); // 19
  let cantidad = parseFloat(producto.cantidad); // 9
  let volumen_entregado = parseFloat(producto.volumen_entregado); // 5

  costo_real = costo_unitario * (cantidad - volumen_entregado); // 19 * 4 = 76

  return costo_real.toFixed(2);
}

function calculateLitros(productos) {
  if (!productos) return 0.0;
  let litros = 0.0;

  productos.forEach((producto) => {
    litros += parseFloat(calcularVolumenReal(producto));
  });

  return litros.toFixed(2);
}

function calculateCosto(productos) {
  if (!productos) return 0.0;
  let costo = 0.0;

  productos.forEach((producto) => {
    costo += parseFloat(calcularCostoReal(producto));
  });

  return costo.toFixed(2);
}

export const formatFactura = (factura) => {
  return {
    id: factura.id,
    secuencia: factura.secuencia,
    folio: factura.folio,
    fecha: factura.fecha,
    serie: factura.serie,
    num_cliente: factura.receptor?.clave_receptor
      ? factura.receptor.clave_receptor
      : "n/a",
    cliente: factura.receptor?.nombre ? factura.receptor.nombre : "n/a",
    reenvios: factura.reenvios,
    status: factura.status,
    categoria_sac: factura?.categoria ? factura.categoria : "Sin categoría SAC",
    categoria_alm_bd: factura?.categoria_alm
      ? factura.categoria_alm
      : "Sin recibir",
    categoria_alm: "Sin recibir",
    pedido: factura?.complementos[0]?.pedido_interno
      ? factura.complementos[0].pedido_interno
      : "Sin pedido",
    embarque: factura?.complementos[0]?.num_embarque
      ? factura.complementos[0].num_embarque
      : "Sin embarque",
    observaciones: factura?.complementos[0]?.observaciones
      ? factura.complementos[0].observaciones
      : null,
    user_observaciones: factura?.complementos[0]?.user_observaciones
      ? factura.complementos[0].user_observaciones
      : null,
    vendedor: factura?.complementos[0]?.vendedor
      ? factura.complementos[0].vendedor
      : "Sin vendedor",
    total: factura.total,
    total_carga: factura?.complementos[0]?.total_carga
      ? factura.complementos[0].total_carga
      : 0.0,
    productos: factura?.detalles_cotizaciones,
    litros: calculateLitros(factura?.detalles_cotizaciones),
    costo: calculateCosto(factura?.detalles_cotizaciones),
  };
};

export const formatFacturaCobranza = (factura, context) => {
  const documentContext =
    context === "sellerDocuments" ? "sellerDocuments" : "addedDocuments";
  return {
    id: factura.id,
    cliente: factura.receptor?.clave_receptor
      ? factura.receptor.clave_receptor
      : "n/a",
    nombre_cliente: factura.receptor?.nombre ? factura.receptor.nombre : "n/a",
    factura: factura.folio,
    tipo: factura.tipodecomprobante === "I" ? "RV" : "NC",
    credito: "--",
    demora: "--",
    total: factura.total,
    saldo: "--",
    status: "Pend de escanear",
    fechaFactura: factura.fecha,
    moneda: factura.moneda,
    enableDelete: context === "sellerInvoices" ? false : true,
    context: documentContext,
  };
};
