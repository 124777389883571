import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme, createTheme } from "@material-ui/core/styles";
import {
  Drawer,
  List,
  CssBaseline,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  ChevronRight,
  ChevronLeft,
  // Home,
  People,
  Search,
  History,
  Web,
  LocalShipping,
  ShoppingCart,
  AccountBalanceWallet,
  SwapHoriz,
  Telegram,
  Settings,
  SyncDisabled,
  Folder,
  // CheckCircle,
  Assignment,
} from "@material-ui/icons";
import OilBarrelIcon from "@mui/icons-material/OilBarrel";
import { Diversity1 } from "@mui/icons-material";
import { selectUser } from "../features/userSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PasswordChange from "./PasswordChange";
import { checkRole } from "../helpers/CheckRole";
import { checkPermission } from "../helpers/CheckPermission";
import { checkDepartment } from "../helpers/CheckDepartment";
import { ROLES } from "../constants";
// import { version } from "../../package.json";
import packageInfo from "../../package.json";

const drawerWidth = 300;

const theme = createTheme();

theme.typography.body2 = {
  fontSize: "1.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    textAlign: "center",
    padding: "5px",
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    //backgroundColor: "#fff",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MUIDrawer({ showNav, setShowNav, pathnames }) {
  const classes = useStyles();
  const theme = useTheme();
  const { department_id, role_id, permissions, logo } = useSelector(selectUser);
  const [changePassword, setChangePassword] = useState(false);
  const navigate = useNavigate();
  const version = packageInfo.version;
  //const [open, setOpen] = React.useState(false);

  const user_menu = [
    checkRole([ROLES.SuperUser], role_id)
      ? {
          path: "/usuarios",
          icon: <People />,
          name: "Usuarios",
        }
      : checkRole([ROLES.Admin], role_id) &&
        checkPermission(["crud: normal users"], permissions) && {
          path: "/usuarios",
          icon: <People />,
          name: "Usuarios",
        },
    // {
    //   path: "/dashboard",
    //   icon: <Home />,
    //   name: "Dashboard",
    // },
    {
      path: "/buscar_factura",
      icon: <Search />,
      name: "Buscar Factura",
    },
    (checkRole([ROLES.SuperUser], role_id) ||
      checkDepartment(["SAC", "GER"], department_id) ||
      checkPermission(["subsidiary admin"], permissions) ||
      (checkRole([ROLES.Gestor, ROLES.SubsidiaryAdmin], role_id) &&
        checkDepartment(["SAC", "CXC"], department_id))) && {
      path: "/transaccion_por_categoria",
      icon: <Telegram />,
      name: "Transacción por categoría",
    },
    {
      path: "/transaccion_express",
      icon: <SwapHoriz />,
      name: "Transacción Express",
    },
    {
      path: "/historial_transacciones",
      icon: <History />,
      name: "Historial de Transacciones",
    },
    checkDepartment(["SAC", "CXC"], department_id) && {
      path: "/portales",
      icon: <Web />,
      name: "Mis Portales",
    },
    (checkDepartment(["ALM", "GER"], department_id) ||
      checkRole([ROLES.SuperUser, ROLES.Admin], role_id)) && {
      path: "/rutas",
      icon: <LocalShipping />,
      name: "Rutas",
    },
    (checkDepartment(["ALM", "GER"], department_id) ||
      checkRole([ROLES.SuperUser, ROLES.Admin], role_id)) && {
      path: "/facturas_por_surtir",
      icon: <ShoppingCart />,
      name: "Facturas por surtir",
    },
    (checkDepartment(["ALM"], department_id) ||
      checkRole([ROLES.SuperUser], role_id) ||
      checkPermission(["maleche"], permissions)) &&
      process.env.REACT_APP_COMPANY !== "CILLASA" && {
        path: "/leo_y_checo",
        icon: <Diversity1 />,
        name: "Maleche",
      },
    (checkRole([ROLES.SuperUser, ROLES.SubsidiaryAdmin], role_id) ||
      checkDepartment(["CXC", "GER"], department_id) ||
      checkPermission(["subsidiary admin"], permissions) ||
      (checkRole([ROLES.Gestor, ROLES.SubsidiaryAdmin], role_id) &&
        checkDepartment(["SAC", "CXC"], department_id))) && {
      path: "/cobranza",
      icon: <AccountBalanceWallet />,
      name: "Cobranza",
    },
    (checkRole([ROLES.SuperUser, ROLES.SubsidiaryAdmin], role_id) ||
      checkDepartment(["CXC", "GER"], department_id) ||
      checkPermission(["subsidiary admin"], permissions) ||
      (checkRole([ROLES.Gestor], role_id) &&
        checkDepartment(["SAC", "CXC"], department_id))) && {
      path: "/archivar_facturas",
      icon: <Folder />,
      name: "Archivar Facturas",
    },
    {
      path: "/no_movimiento",
      icon: <SyncDisabled />,
      name: "Facturas no movimiento",
    },
    (checkDepartment(["ALM"], department_id) ||
      checkRole([ROLES.SuperUser], role_id)) &&
      process.env.REACT_APP_COMPANY === "LUBTRAC" && {
        path: "/asignar_codigos",
        icon: <OilBarrelIcon />,
        name: "Asignación de códigos",
      },
    (checkDepartment(["ALM"], department_id) ||
      checkRole([ROLES.SuperUser], role_id)) &&
      process.env.REACT_APP_COMPANY === "LUBTRAC" && {
        path: "/ordenes_compra",
        icon: <Assignment />,
        name: "Órdenes de compra",
      },
  ];
  const handleDrawerClose = () => {
    setShowNav(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: showNav,
          [classes.drawerClose]: !showNav,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: showNav,
            [classes.drawerClose]: !showNav,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRight />
            ) : (
              <>
                <ChevronLeft />
              </>
            )}
          </IconButton>
        </div>
        {showNav && <img src={logo} alt="Logo" className="logo" />}
        {showNav && (
          <Typography className={classes.title} variant="h6">
            Tracking de Facturas
          </Typography>
        )}
        <Divider />
        <List>
          {user_menu.map((module, index) => {
            return module.path ? (
              <ListItem
                button
                key={module.name}
                onClick={() => navigate(module.path)}
                style={
                  "/" + pathnames[0] === module.path
                    ? {
                        backgroundColor: "#cfd8dc",
                      }
                    : {
                        backgroundColor: "#fff",
                      }
                }
              >
                <ListItemIcon>{module.icon}</ListItemIcon>
                <ListItemText primary={module.name} />
              </ListItem>
            ) : null;
          })}
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => setChangePassword(true)}>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText>Cambiar contraseña</ListItemText>
          </ListItem>
          <ListItem style={{
            marginLeft: "-6px",
          }}>
            <ListItemText>
              <Typography
                variant="body2"
                color="textSecondary"
                // align="center"
              >
                V {version}
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <PasswordChange
        changePassword={changePassword}
        setChangePassword={setChangePassword}
      />
    </div>
  );
}
