import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import url from "../url";

export const getUsuarios = createAsyncThunk(
  "/api/usuarios",
  async (thunkAPI) => {
    return await axios
      .get(url.concat(`api/usuarios`), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const createUser = createAsyncThunk(
  "/api/usuarios/post",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/signin"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const updateUser = createAsyncThunk(
  "/api/usuarios/update",
  async ({ data }, thunkAPI) => {
    return await axios
      .put(url.concat("api/usuarios/update"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const desactiveUser = createAsyncThunk(
  "/api/usuarios/desactivar",
  async ({ data }, thunkAPI) => {
    return await axios
      .put(url.concat(`api/usuarios/desactivar`), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

const initialState = {
  usuarios: [],
  isFetchingUsuarios: false,
  isSuccessUsuarios: false,
  isErrorUsuarios: false,
  isFetchingCreate: false,
  isSuccessCreate: false,
  isErrorCreate: false,
  isSuccessUpdate: false,
  isFetchingUpdate: false,
  isErrorUpdate: false,

  isFetchingDesactivar: false,
  isSuccessDesactivar: false,
  isErrorDesactivar: false,

  successMessage: "",
  errorMessage: "",
};

export const adminSlice = createSlice({
  name: "admin",
  initialState: initialState,
  reducers: {
    clearUsuarios: (state) => {
      state.isFetchingUsuarios = false;
      state.isSuccessUsuarios = false;
      state.isErrorUsuarios = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    clearStateCreate: (state) => {
      state.isFetchingCreate = false;
      state.isSuccessCreate = false;
      state.isErrorCreate = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    clearStateUpdate: (state) => {
      state.isFetchingUpdate = false;
      state.isSuccessUpdate = false;
      state.isErrorUpdate = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    clearStateDesactivar: (state) => {
      state.isFetchingDesactivar = false;
      state.isSuccessDesactivar = false;
      state.isErrorDesactivar = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [getUsuarios.fulfilled]: (state, { payload }) => {
      state.isFetchingUsuarios = false;
      state.isSuccessUsuarios = true;
      let usersArray = payload.data;
      usersArray = usersArray.map((user) => {
        return {
          id: user.id,
          name: user.name,
          email: user.email,
          department: user.department.name,
          department_id: user.department.id,
          subsidiary: user.subsidiary.name,
          subsidiary_id: user.subsidiary.id,
          role_id: user.role.id,
          role: user.role.description,
          rutas: user?.routes?.length > 0 ? user.routes.map((r) => r.gven) : [],
          dsr_id: user.dsr_id
        };
      });
      state.usuarios = usersArray;
    },
    [getUsuarios.pending]: (state) => {
      state.isFetchingUsuarios = true;
    },
    [getUsuarios.rejected]: (state, { payload }) => {
      state.isErrorUsuarios = true;
      state.isFetchingUsuarios = false;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [createUser.fulfilled]: (state, { payload }) => {
      state.isFetchingCreate = false;
      state.isSuccessCreate = true;
      state.successMessage = "Usuario creado con éxito";
      state.usuarios = [...state.usuarios, {
        id: payload.data.id,
        name: payload.data.name,
        email: payload.data.email,
        department: payload.data.department.name,
        department_id: payload.data.department.id,
        subsidiary: payload.data.subsidiary.name,
        subsidiary_id: payload.data.subsidiary.id,
        role_id: payload.data.role.id,
        role: payload.data.role.description,
        rutas:
          payload.data?.routes?.length > 0
            ? payload.data.routes.map((r) => r.gven)
            : [],
      }];
    },
    [createUser.pending]: (state) => {
      state.isFetchingCreate = true;
    },
    [createUser.rejected]: (state, { payload }) => {
      state.isErrorCreate = true;
      state.isFetchingCreate = false;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.isFetchingUpdate = false;
      state.isSuccessUpdate = true;
      state.successMessage = "Usuario actualizado con éxito";
      state.usuarios = state.usuarios.map((usuario) => {
        if (usuario.id === payload.data.id) {
          return {
            id: payload.data.id,
            name: payload.data.name,
            email: payload.data.email,
            department: payload.data.department.name,
            department_id: payload.data.department.id,
            subsidiary: payload.data.subsidiary.name,
            subsidiary_id: payload.data.subsidiary.id,
            role_id: payload.data.role.id,
            role: payload.data.role.description,
            rutas:
              payload.data?.routes?.length > 0
                ? payload.data.routes.map((r) => r.gven)
                : [],
          };
        }
        return usuario;
      });
    },
    [updateUser.pending]: (state) => {
      state.isFetchingUpdate = true;
    },
    [updateUser.rejected]: (state, { payload }) => {
      state.isErrorUpdate = true;
      state.isFetchingUpdate = false;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [desactiveUser.fulfilled]: (state, { payload }) => {
      state.isFetchingDesactivar = false;
      state.isSuccessDesactivar = true;
      state.successMessage = payload.message;
      state.usuarios = state.usuarios.filter(
        (usuario) => usuario.id !== payload.data.id
      );
    },
    [desactiveUser.pending]: (state) => {
      state.isFetchingDesactivar = true;
    },
    [desactiveUser.rejected]: (state, { payload }) => {
      state.isFetchingDesactivar = false;
      state.isErrorDesactivar = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
  },
});

export const {
  clearUsuarios,
  clearStateCreate,
  clearStateUpdate,
  clearStateDesactivar,
  reset,
} = adminSlice.actions;

export const selectAdmin = (state) => state.admin;

export default adminSlice.reducer;
