import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import url from "../url";

export const getAllDeliveriesReception = createAsyncThunk(
  "deliveryReception/getAllDeliveriesReception",
  async (params, thunkAPI) => {
    return await axios
      .get(`${url}api/deliveries`, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.response.data);
      });
  }
);

export const getDeliveryDetail = createAsyncThunk(
  "deliveryReception/getDeliveryDetail",
  async (id, thunkAPI) => {
    return await axios
      .get(`${url}api/deliveries/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.response.data);
      });
  }
);

export const createDeliveryReception = createAsyncThunk(
  "deliveryReception/createDeliveryReception",
  async (data, thunkAPI) => {
    return await axios
      .post(`${url}api/deliveries`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.response.data);
      });
  }
);

export const addDocumentsToDelivery = createAsyncThunk(
  "deliveryReception/addDocumentsToDelivery",
  async (data, thunkAPI) => {
    return await axios
      .post(`${url}api/deliveries/${data.delivery_reception_id}/edit`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.response.data);
      });
  }
);

export const editDeliveryGenerals = createAsyncThunk(
  "deliveryReception/editDeliveryGenerals",
  async (data, thunkAPI) => {
    return await axios
      .put(`${url}api/deliveries/${data.id}/edit`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.response.data);
      });
  }
);

export const getScannedProduct = createAsyncThunk(
  "deliveryReception/getScannedProduct",
  async (data, thunkAPI) => {
    return await axios
      .post(`${url}api/deliveries/scan/product`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.response.data);
      });
  }
);

export const startDelivery = createAsyncThunk(
  "deliveryReception/startDelivery",
  async (id, thunkAPI) => {
    return await axios
      .put(`${url}api/deliveries/${id}/start`, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.response.data);
      });
  }
);

export const endDelivery = createAsyncThunk(
  "deliveryReception/endDelivery",
  async (data, thunkAPI) => {
    const { id } = data;
    return await axios
      .post(`${url}api/deliveries/${id}/end`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.response.data);
      });
  }
);

export const getCheckListPdf = createAsyncThunk(
  "deliveryReception/getCheckListPdf",
  async (id, thunkAPI) => {
    return await axios
      .get(`${url}api/deliveries/${id}/checklist`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", "checklist" + id + ".xlsx"); // Nombre del archivo que se descargará
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(async (e) => {
        let responseObj = await e.response.data.text();
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(responseObj);
      });
  }
);

export const getDeliveryStatuses = createAsyncThunk(
  "deliveryReception/getDeliveryStatuses",
  async (_, thunkAPI) => {
    return await axios
      .get(`${url}api/deliveries/statuses`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.response.data);
      });
  }
);

const initialState = {
  deliveries: {
    data: [],
    perPage: 10,
    page: 1,
    total: 0,
  },
  deliveryDetail: {
    id: "",
    parcel_name: "",
    unit_number: "",
    incidents: [],
    status: {
      id: null,
      name: "",
    },
    facturas: [
      {
        folio: "",
      },
    ],
    ordenes_compra: [
      {
        id: "",
        num_pedido: "",
        products: [
          {
            id: "",
            codigo: "",
            descripcion: "",
            cantidad_sap: "",
            cantidad_facturada: "",
            cantidad_recibida: "",
            prespik: "",
          },
        ],
      },
    ],
  },

  isFetchingDeliveries: false,
  isSuccessDeliveries: false,
  isErrorDeliveries: false,

  isFetchingDeliveryDetail: false,
  isSuccessDeliveryDetail: false,
  isErrorDeliveryDetail: false,

  isFetchingDeliveryCreate: false,
  isSuccessDeliveryCreate: false,
  isErrorDeliveryCreate: false,

  isFetchingDeliveryEnd: false,
  isSuccessDeliveryEnd: false,
  isErrorDeliveryEnd: false,

  isFetchingScannedProduct: false,
  isSuccessScannedProduct: false,
  isErrorScannedProduct: false,

  isFetchingChangeStatus: false,
  isSuccessChangeStatus: false,
  isErrorChangeStatus: false,

  isFetchingCheckListPdf: false,
  isSuccessCheckListPdf: false,
  isErrorCheckListPdf: false,

  isFetchingDeliveryEdit: false,
  isSuccessDeliveryEdit: false,
  isErrorDeliveryEdit: false,

  successMessage: "",
  errorMessage: "",

  error: {
    code: "",
    message: "",
    data: {
      productInInvoice: {},
      exxonDescription: {
        0: "",
      },
      invoice: {
        0: "",
      },
    },
  },
};

const deliveryReceptionSlice = createSlice({
  name: "deliveryReception",
  initialState,
  reducers: {
    resetSuccessMessage: (state) => {
      // state.isSuccessDeliveries = false;
      state.successMessage = "";
    },
    resetErrorMessage: (state) => {
      // state.isErrorDeliveries = false;
      state.errorMessage = "";
    },
    clearStateFetch: (state) => {
      state.isFetchingDeliveries = false;
      state.isSuccessDeliveries = false;
      state.isErrorDeliveries = false;
    },
    clearStateFetchDetail: (state) => {
      state.isFetchingDeliveryDetail = false;
      state.isSuccessDeliveryDetail = false;
      state.isErrorDeliveryDetail = false;
    },
    clearStateCreate: (state) => {
      state.isFetchingDeliveryCreate = false;
      state.isSuccessDeliveryCreate = false;
      state.isErrorDeliveryCreate = false;
    },
    clearStateScannedProduct: (state) => {
      state.isFetchingScannedProduct = false;
      state.isSuccessScannedProduct = false;
      state.isErrorScannedProduct = false;
      state.errorMessage = "";
    },
    clearStateStatusChange: (state) => {
      state.isFetchingChangeStatus = false;
      state.isSuccessChangeStatus = false;
      state.isErrorChangeStatus = false;
    },
    updateCantidadRecibida: (state, action) => {
      const {
        delivery_invoice_detail_id,
        cantidad_recibida,
        selectedOrdenCompra,
      } = action.payload;

      let ordenCompra = state.deliveryDetail.ordenes_compra.find(
        (orden) => orden.num_pedido === selectedOrdenCompra
      );

      let detalle = ordenCompra.products.find(
        (det) => det.delivery_invoice_detail_id === delivery_invoice_detail_id
      );

      detalle.cantidad_recibida = cantidad_recibida;

      return state;
    },
    updateBatchNumber: (state, action) => {
      const { delivery_invoice_detail_id, selectedOrdenCompra, batch_number } =
        action.payload;

      let ordenCompra = state.deliveryDetail.ordenes_compra.find(
        (orden) => orden.num_pedido === selectedOrdenCompra
      );

      let detalle = ordenCompra.products.find(
        (det) => det.delivery_invoice_detail_id === delivery_invoice_detail_id
      );

      detalle.batch_number = batch_number;

      return state;
    },
    addIncident: (state, action) => {
      const { type, product } = action.payload;

      let incidentExists = false;
      let incidents = state.deliveryDetail.incidents;

      let newIncidents = incidents.map((inc, index) => {
        if (inc.type === type) {
          incidentExists = true;
          let products = inc.products;
          //check if product exists, if not, add it
          let productExists = products.find((prod) => prod === product);
          if (!productExists) {
            products.push(product);
          }
          return { type: type, products: products };
        } else {
          return inc;
        }
      });

      if (!incidentExists) {
        newIncidents.push({
          type: type,
          products: [product],
        });
      }

      state.deliveryDetail.incidents = newIncidents;
      return state;
    },
    addIncidents: (state, action) => {
      state.deliveryDetail.incidents = action.payload;
      return state;
    },
    removeIncident: (state, action) => {
      const { index } = action.payload;
      state.deliveryDetail.incidents.splice(index, 1);

      return state;
    },
    clearStateEndDelivery: (state) => {
      state.isFetchingDeliveryEnd = false;
      state.isSuccessDeliveryEnd = false;
      state.isErrorDeliveryEnd = false;
    },
    clearStateCheckListPdf: (state) => {
      state.isFetchingCheckListPdf = false;
      state.isSuccessCheckListPdf = false;
      state.isErrorCheckListPdf = false;
    },
    reset: () => initialState,
    clearStateEditDelivery: (state) => {
      state.isFetchingDeliveryEdit = false;
      state.isSuccessDeliveryEdit = false;
      state.isErrorDeliveryEdit = false;
    },
  },
  extraReducers: {
    [getAllDeliveriesReception.pending]: (state) => {
      state.isFetchingDeliveries = true;
      state.isSuccessDeliveries = false;
      state.isErrorDeliveries = false;
    },
    [getAllDeliveriesReception.fulfilled]: (state, { payload }) => {
      let deliveries = payload.data;
      // deliveries = deliveries.data.map((delivery) => {
      //   return {
      //     ...delivery,
      //     status: delivery?.status?.name ?? "Sin estatus",
      //     subsidiary_name: delivery?.subsidiary?.name ?? "Sin sucursal",
      //   };
      // });
      state.deliveries = {
        data: deliveries.data.map((delivery) => {
          return {
            ...delivery,
            status: delivery?.status?.name ?? "Sin estatus",
            subsidiary_name: delivery?.subsidiary?.name ?? "Sin sucursal",
          };
        }),
        perPage: deliveries.per_page,
        page: deliveries.current_page,
        total: deliveries.total,
      };
      state.isFetchingDeliveries = false;
      state.isSuccessDeliveries = true;
      state.isErrorDeliveries = false;
    },
    [getAllDeliveriesReception.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isFetchingDeliveries = false;
      state.isSuccessDeliveries = false;
      state.isErrorDeliveries = true;
      state.errorMessage = payload?.message ?? "Error al obtener las entregas";
    },
    [getDeliveryDetail.pending]: (state) => {
      state.isFetchingDeliveryDetail = true;
      state.isSuccessDeliveryDetail = false;
      state.isErrorDeliveryDetail = false;
    },
    [getDeliveryDetail.fulfilled]: (state, { payload }) => {
      state.deliveryDetail = payload.data;
      state.isFetchingDeliveryDetail = false;
      state.isSuccessDeliveryDetail = true;
      state.isErrorDeliveryDetail = false;
    },
    [getDeliveryDetail.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isFetchingDeliveryDetail = false;
      state.isSuccessDeliveryDetail = false;
      state.isErrorDeliveryDetail = true;
      state.errorMessage = payload.message;
    },
    [createDeliveryReception.pending]: (state) => {
      state.isFetchingDeliveryCreate = true;
      state.isSuccessDeliveryCreate = false;
      state.isErrorDeliveryCreate = false;
    },
    [createDeliveryReception.fulfilled]: (state, { payload }) => {
      state.isFetchingDeliveryCreate = false;
      state.isSuccessDeliveryCreate = true;
      state.isErrorDeliveryCreate = false;
      state.successMessage = payload.message;
    },
    [createDeliveryReception.rejected]: (state, { payload }) => {
      state.error = {
        code: payload?.error?.code ?? "",
        message: payload?.error?.message ?? "",
        data: payload?.data ?? {},
      };
      state.isFetchingDeliveryCreate = false;
      state.isSuccessDeliveryCreate = false;
      state.isErrorDeliveryCreate = true;
      state.errorMessage = payload.message;
    },
    [getScannedProduct.pending]: (state) => {
      state.isFetchingScannedProduct = true;
      state.isSuccessScannedProduct = false;
      state.isErrorScannedProduct = false;
    },
    [getScannedProduct.fulfilled]: (state, { payload }) => {
      state.isFetchingScannedProduct = false;
      state.isSuccessScannedProduct = true;
      state.isErrorScannedProduct = false;
      state.successMessage = payload.message;
    },
    [getScannedProduct.rejected]: (state, { payload }) => {
      state.error = payload?.error ?? state.error;
      state.isFetchingScannedProduct = false;
      state.isSuccessScannedProduct = false;
      state.isErrorScannedProduct = true;
      state.errorMessage = payload.message;
    },
    [startDelivery.pending]: (state) => {
      state.isFetchingChangeStatus = true;
      state.isSuccessChangeStatus = false;
      state.isErrorChangeStatus = false;
    },
    [startDelivery.fulfilled]: (state, { payload }) => {
      state.isFetchingChangeStatus = false;
      state.isSuccessChangeStatus = true;
      state.isErrorChangeStatus = false;
      state.successMessage = payload.message;

      state.deliveryDetail = {
        ...state.deliveryDetail,
        status: payload.data.status,
      };
    },
    [startDelivery.rejected]: (state, { payload }) => {
      state.error = payload?.error ?? state.error;
      state.isFetchingChangeStatus = false;
      state.isSuccessChangeStatus = false;
      state.isErrorChangeStatus = true;
      state.errorMessage = payload.message;
    },
    [endDelivery.pending]: (state) => {
      state.isFetchingDeliveryEnd = true;
      state.isSuccessDeliveryEnd = false;
      state.isErrorDeliveryEnd = false;
    },
    [endDelivery.fulfilled]: (state, { payload }) => {
      state.isFetchingDeliveryEnd = false;
      state.isSuccessDeliveryEnd = true;
      state.isErrorDeliveryEnd = false;
      state.successMessage = payload.message;

      state.deliveryDetail = {
        ...state.deliveryDetail,
        status: payload.data.status,
      };
    },

    [endDelivery.rejected]: (state, { payload }) => {
      state.error = payload?.error ?? state.error;
      state.isFetchingDeliveryEnd = false;
      state.isSuccessDeliveryEnd = false;
      state.isErrorDeliveryEnd = true;
      state.errorMessage = payload.message;
    },

    [getCheckListPdf.pending]: (state) => {
      state.isFetchingCheckListPdf = true;
      state.isSuccessCheckListPdf = false;
      state.isErrorCheckListPdf = false;
    },
    [getCheckListPdf.fulfilled]: (state) => {
      state.isFetchingCheckListPdf = false;
      state.isSuccessCheckListPdf = true;
      state.isErrorCheckListPdf = false;
    },
    [getCheckListPdf.rejected]: (state, { payload }) => {
      let error_message = JSON.parse(payload);
      state.error = error_message;
      state.isFetchingCheckListPdf = false;
      state.isSuccessCheckListPdf = false;
      state.isErrorCheckListPdf = true;
      state.errorMessage = error_message?.message ?? "Error al obtener el PDF";
    },
    [addDocumentsToDelivery.pending]: (state) => {
      state.isFetchingDeliveryEdit = true;
      state.isSuccessDeliveryEdit = false;
      state.isErrorDeliveryEdit = false;
    },
    [addDocumentsToDelivery.fulfilled]: (state, { payload }) => {
      state.isFetchingDeliveryEdit = false;
      state.isSuccessDeliveryEdit = true;
      state.isErrorDeliveryEdit = false;
      state.successMessage = payload.message;
    },
    [addDocumentsToDelivery.rejected]: (state, { payload }) => {
      state.error = {
        code: payload?.error?.code ?? "",
        message: payload?.error?.message ?? "",
        data: payload?.data ?? {},
      };
      state.isFetchingDeliveryEdit = false;
      state.isSuccessDeliveryEdit = false;
      state.isErrorDeliveryEdit = true;
      state.errorMessage = payload.message;
    },
    [editDeliveryGenerals.pending]: (state) => {
      state.isFetchingDeliveryEdit = true;
      state.isSuccessDeliveryEdit = false;
      state.isErrorDeliveryEdit = false;
    },
    [editDeliveryGenerals.fulfilled]: (state, { payload }) => {
      state.isFetchingDeliveryEdit = false;
      state.isSuccessDeliveryEdit = true;
      state.isErrorDeliveryEdit = false;
      state.deliveryDetail = {
        ...state.deliveryDetail,
        parcel_name: payload.data.parcel_name,
        unit_number: payload.data.unit_number,
      };
      state.deliveryDetail.incidents = [];
      state.successMessage = payload.message;
    },
    [editDeliveryGenerals.rejected]: (state, { payload }) => {
      state.error = {
        code: payload?.error?.code ?? "",
        message: payload?.error?.message ?? "",
        data: payload?.data ?? {},
      };
      state.isFetchingDeliveryEdit = false;
      state.isSuccessDeliveryEdit = false;
      state.isErrorDeliveryEdit = true;
      state.errorMessage = payload.message;
    },
  },
});

export const {
  resetSuccessMessage,
  resetErrorMessage,
  reset,
  clearStateFetch,
  clearStateFetchDetail,
  clearStateCreate,
  clearStateEndDelivery,
  clearStateCheckListPdf,
  clearStateScannedProduct,
  updateCantidadRecibida,
  updateBatchNumber,
  addIncident,
  addIncidents,
  removeIncident,
  clearStateEditDelivery,
} = deliveryReceptionSlice.actions;

export default deliveryReceptionSlice.reducer;
